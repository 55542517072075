let intialState = {
    isSubjectLoading: true,
    isBubbleLoading: true,
    isLoading: true,
    isShow: false,
    program_id: '',
    subjectCode: '',
    note: null,
    qIndex: 0,
    filterPrevResources: [],
    resourceIndex: [],
    isQuizComplete: false,
    remainQuestion: [],
    isSkillLoading: true,
    bookmarked: false
}
let temp
export default function SkillLibReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Get_Details':
            stateCopy.studentDetails = action.payload;
            return stateCopy;
        case "Get_Bookmarked_Skill":
            stateCopy.bookmarkedSkill = action.payload;
            stateCopy.isSubjectLoading = false;
            return stateCopy;
        case "Get_Explore_Skill":
            stateCopy.exploreSkill = action.payload;
            stateCopy.isSkillLoading = false;
            return stateCopy;
        case 'Get_Next_Skill':
            stateCopy.exploreSkill = stateCopy.exploreSkill.concat(action.payload);
            return stateCopy;
        case 'Skill_Toggle_Bookmark':
            if (action.payload.bookmark) {
                stateCopy.bookmarkedSkill.forEach(el => {
                    if (el._id === action.payload.id) {
                        el.bookmarked = false;
                    }
                })
            }
            else {
                stateCopy.exploreSkill.forEach(el => {
                    if (el._id === action.payload.id) {
                        el.bookmarked = true;
                    }
                })
            }
            return stateCopy;
        case 'Get_Skill_Pod':
            stateCopy.skillPod = action.payload;
            stateCopy.bookmarked = stateCopy.skillPod[0].bookmarked;
            stateCopy.isBubbleLoading = false;
            return stateCopy;
        case 'Bookmarked_done':
            console.log('ared', action.payload)
            if (action.payload) {
                stateCopy.bookmarked = false;
            }
            else {
                stateCopy.bookmarked = true;
            }
            return stateCopy;
        case 'Get_Content_Count':
            stateCopy.articles = action.payload.articles;
            stateCopy.videos = action.payload.videos;
            stateCopy.courses = action.payload.free_courses;
            stateCopy.podcasts = action.payload.podcast;
            return stateCopy;
        case 'Get_Pod_Resources':
            var topics = [...new Set(action.payload.data.sort((a, b) => (a.index > b.index) ? 1 : -1).map(el => el.topic))].map(topic => ({ topic, complete: false }));
            const content = topics.map(data => { return action.payload.data.sort((a, b) => (a.index > b.index) ? 1 : -1).filter(el => { return data.topic === el.topic }) });
            stateCopy.topics = getComplete(topics, content);
            stateCopy.resourceIndex = [].concat.apply([], content);
            stateCopy.resourcesCopy = action.payload.data;
            stateCopy.resources = content;
            stateCopy.isLoading = false;
            return stateCopy;
        case 'Skill_Path_Analytic_Capture':
            const analytics = stateCopy.resourcesCopy.map(answer => {
                if (answer._id === action.payload.r_id) return {
                    _id: answer._id,
                    title: answer.title,
                    source: answer.source,
                    type: answer.type,
                    skill: answer.skill,
                    liked: answer.liked,
                    topic: answer.topic,
                    image: answer.image,
                    index: answer.index,
                    viewed: answer.viewed ? true : action.payload.viewed
                }
                return answer
            })
            stateCopy.resourcesCopy = analytics;
            const content1 = stateCopy.topics.map(data => { return stateCopy.resourcesCopy.sort((a, b) => (a.index > b.index) ? 1 : -1).filter(el => { return data.topic === el.topic }) });
            stateCopy.topics = getComplete(stateCopy.topics, content1);
            stateCopy.resources = content1;
            return stateCopy;
        case 'Get_Liked_Skill_Resource':
            stateCopy.getIndividualResource.liked = !action.payload.liked;
            return stateCopy;
        // case 'Get_Skill_Notes':
        //     if (action.payload.data !== null) {
        //         stateCopy.note = action.payload.data.note;
        //     }
        //     else {
        //         stateCopy.note = null;
        //     }
        //     return stateCopy;
        case 'Save_Skill_Note':
            return stateCopy;
        case 'Get_Individual_Skill_Resource':
            stateCopy.getIndividualResource = action.payload;
            var found1 = stateCopy.filterPrevResources.find(o => o._id === action.payload._id);
            if (found1 === undefined) {
                stateCopy.filterPrevResources = [...stateCopy.filterPrevResources, stateCopy.getIndividualResource];
            }
            stateCopy.isShow = true;
            if (action.payload.type === 'Quiz') {
                stateCopy.questions = action.payload.questions;
                stateCopy.qIndex = stateCopy.questions.findIndex(el => { return el.answered === null });
            }
            return stateCopy;
        case 'reset_index':
            stateCopy.isBubbleLoading = true;
            stateCopy.isShow = false;
            return stateCopy;
        case 'Reset_Skill_Index':
            stateCopy.getIndividualResource = {};
            stateCopy.isLoading = true;
            stateCopy.questions = [];
            stateCopy.isShow = false;
            stateCopy.qIndex = stateCopy.questions.findIndex(el => { return el.answered === null });
            return stateCopy;
        case 'Get_Previous_Skill_Index':
            stateCopy.getIndividualResource = stateCopy.filterPrevResources.filter(el => el.id === action.payload._id.toString())[0];
            return stateCopy;
        case 'Get_Skill_Questions':
            stateCopy.question = stateCopy.questions.filter(el => el._id === action.payload._id)[0];
            stateCopy.remainQuestion = [];
            return stateCopy;
        case 'Get_Skill_Answer':
            stateCopy.question.correct = action.payload.data.correct;
            stateCopy.question.correct_id = action.payload.data.correct_id;
            stateCopy.question.answered = action.payload.aid;
            const answersns = stateCopy.questions.map(answer => {
                if (answer._id === action.payload.qid) return {
                    _id: answer._id,
                    title: answer.title,
                    description: answer.description,
                    type: answer.type,
                    skill: answer.skill,
                    liked: answer.liked,
                    answers: answer.answers,
                    correct_id: action.payload.data.correct_id,
                    correct: action.payload.data.correct,
                    answered: action.payload.aid
                }
                return answer
            })
            stateCopy.questions = answersns;
            return stateCopy;
        case "Skill_Quiz_Submit":
            stateCopy.isQuizComplete = true;
            stateCopy.questions.forEach((v, i) => {
                if (v.answered == null) {
                    stateCopy.remainQuestion = [...stateCopy.remainQuestion, i];
                }
                else {
                    console.log(i);
                }
            });
            return stateCopy;
        case "Skill_Save_Score":
            stateCopy.isQuizComplete = false;
            const questions1 = stateCopy.questions.map(answer => {
                return {
                    _id: answer._id,
                    title: answer.title,
                    description: answer.description,
                    type: answer.type,
                    skill: answer.skill,
                    answers: answer.answers,
                    correct_id: null,
                    correct: null,
                    answered: null
                }
            })
            stateCopy.questions = questions1;
            // stateCopy.qIndex = 0;
            return stateCopy;
        case "Close_Skill_Modal":
            stateCopy.isQuizComplete = false;
            stateCopy.remainQuestion = []
            return stateCopy;
        case 'Skill_Retake_Quiz':
            const questions = stateCopy.questions.map(answer => {
                return {
                    _id: answer._id,
                    title: answer.title,
                    description: answer.description,
                    type: answer.type,
                    skill: answer.skill,
                    answers: answer.answers,
                    correct_id: null,
                    correct: null,
                    answered: null
                }
            })
            stateCopy.remainQuestion = []
            stateCopy.questions = questions;
            state.qIndex = 0;
            return stateCopy;
        case 'Share_Popup_Open':
            stateCopy.isShareFeedPopup = true;
            stateCopy.shareObj = action.payload;
            return stateCopy;
        case 'Share_Popup_Close':
            stateCopy.isShareFeedPopup = false;
            stateCopy.shareObj = {};
            return stateCopy;
        case 'Share_Done':
            stateCopy.isShareFeedPopup = false;
            stateCopy.shareObj = {};
            return stateCopy;
        default:
            return stateCopy;
    }
}


const getComplete = (topics, content) => {
    for (var i = 0; i < topics.length; i++) {
        var contentIndex = content[i];
        for (var j = 0; j < contentIndex.length; j++) {
            temp = true;
            if (topics[i].topic === contentIndex[j].topic) {
                if (!contentIndex[j].viewed) {
                    temp = false;
                    break;
                }
            }

        }
        topics[i].complete = temp
    }
    return topics
}